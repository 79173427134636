body {
  height: 20% !important;
  margin: 0;
  font-family: 'Nunito' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  max-width: 1400px;
  margin:    0 auto;
}

code {
  font-family: -apple-system, BlinkMacSystemFont, 'Nunito', sans-serif, arial;
}
img{
  width: 100%;
  height: auto;
}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active
{
 -webkit-box-shadow: 0 0 0 30px white inset !important;
}